<template>
  <div>
    <p class="text-2xl mb-6">Liste des partenaires</p>
    <v-data-table
      dense
      :page="options.page"
      :pageCount="numberOfPages"
      :headers="headers"
      :items="items"
      disable-sort
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100],
      }"
      :items-per-page="options.itemsPerPage"
    >
      <template v-slot:item.active="{ item }">
        <v-icon color="primary" v-if="item.active">
          {{ icons.mdiCheck }}
        </v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <div>
          <v-btn
            :to="`/admin/partner/edit/${item.id}`"
            class="mx-2"
            elevation="0"
            fab
            small
          >
            <v-icon color="primary">
              {{ icons.mdiPencil }}
            </v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mdiCheck, mdiPencil, mdiTrashCan } from "@mdi/js";
import axios from "@axios";

import compact from "lodash/compact";

export default {
  name: "PartnerList",
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 100,
      },
      total: 0,
      numberOfPages: 0,
      items: [],
      headers: [
        { text: "Name", value: "name" },
        { text: "Code", value: "code" },
        { text: "Actif", value: "active" },
        { text: "Devise", value: "currency" },
        { text: "Natures", value: "natures" },
        { text: "CDG", value: "cdg_name" },
        { text: "Actions", value: "actions" },
      ],
      icons: {
        mdiCheck,
        mdiPencil,
        mdiTrashCan,
      },
    };
  },
  created() {
    this.getData();

    this.$store.dispatch("admin/getPartnerCurrencyList");
    this.$store.dispatch("admin/getPartnerNatureList");
  },
  methods: {
    formatResult(data) {
      return {
        ...data,
        currency:
          this.currencies.find(
            (currency) => currency.id === data.currency_id ?? 0
          )?.name ?? "",
        natures: compact(
          (data.nature_id_list ?? "")
            .split(",")
            .map(
              (nature_id) =>
                this.natures.find((nature) => nature.id === parseInt(nature_id))
                  ?.name
            )
        ).join(", "),
      };
    },
    async getData() {
      if (!this.currencies || !this.natures || this.loading) {
        return;
      }

      this.loading = true;
      const { page, itemsPerPage } = this.options;
      const queryParams = {
        page,
        limit: itemsPerPage,
      };

      const { data } = await axios.get("/partners/full_list", {
        params: queryParams,
      });
      this.items = data.items.map((item) => this.formatResult(item));
      this.total = data.total;
      this.numberOfPages = data.total / this.options.itemsPerPage;

      this.loading = false;
    },
    refreshWindow() {
      this.$router.go();
    },
  },
  computed: {
    currencies() {
      return this.$store.getters["admin/getPartnerCurrencyList"];
    },
    natures() {
      return this.$store.getters["admin/getPartnerNatureList"];
    },
  },
  watch: {
    currencies() {
      this.getData();
    },
    natures() {
      this.getData();
    },
  },
};
</script>

<style scoped></style>
